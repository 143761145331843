import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import moment, { now } from "moment";
import axios from "axios";

const _pendiente = {label:'Pendiente', description:'Su solicitud ha sido recibida', date:null, display:false};
const _enPreparacion = {label:'En preparación', description:'Ya estamos armando su pedido', date:null, display:false};
const _enDistribucion = {label:'En distribución', description:'Su pedido está en viaje', date:null, display:false};
const _entregado = {label:'Entregado', description:'Ya hemos entregado su pedido', date:null, display:false};

const REQUEST_URL = `${process.env.REACT_APP_API_URL}/product/request`;

const pad =  (str, max) => {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

const habilita_estado_new_order = (events)=>{
	const cant_new_order = events.filter(e=>e.event_type.name=="NEW_ORDER").length;
	
	if(cant_new_order>0)
		return true;
	else
		return false;
}

const habilita_estado_en_preparacion = (events)=>{
	const cant_remove = events.filter(e=>e.event_type.name=="REMOVE_FROM_WAVE").length;
	const cant_add_to_wave = events.filter(e=>e.event_type.name=="ADD_TO_WAVE").length;
	
	const max_id = events
		.filter(e=> e.event_type.name=='ADD_TO_WAVE' || 
			e.event_type.name=='SHIPP' || 
			e.event_type.name=='WAVE_READY' || 
			e.event_type.name=='ADD_TO_DISPATCH')
		.reduce((acc, val)=>{
			if(val.id > acc)
				acc = val.id;
			return acc;
		},0);
	
	if(cant_remove>=cant_add_to_wave || cant_add_to_wave==0){
		return {habilitado:false, fecha:null};
	}
	else{
		return {habilitado:true, fecha:events.filter(e=>e.id==max_id)[0].event_date};
	}
}

const habilita_estado_en_despacho = (events)=>{
	const cant_remove = events.filter(e=>e.event_type.name=="REMOVE_FROM_DISPATCH").length;
	const cant_dispatch = events.filter(e=>e.event_type.name=="DISPATCH_READY").length;
	
	const max_id = events
		.filter(e=> e.event_type.name=='DISPATCH_READY')
		.reduce((acc, val)=>{
			if(val.id > acc)
				acc = val.id;
			return acc;
		},0);
	
	if(cant_remove>=cant_dispatch || cant_dispatch==0){
		return {habilitado:false, fecha:null};
	}
	else{
		return {habilitado:true, fecha:events.filter(e=>e.id==max_id)[0].event_date};
	}
}

const habilita_estado_entregado = (events)=>{
	const cant_dispatch = events.filter(e=>e.event_type.name=="ORDER_DELIVERED").length;
	
	const max_id = events
		.filter(e=> e.event_type.name=='ORDER_DELIVERED')
		.reduce((acc, val)=>{
			if(val.id > acc)
				acc = val.id;
			return acc;
		},0);
	
	if(cant_dispatch<=0){
		return {habilitado:false, fecha:null};
	}
	else{
		return {habilitado:true, fecha:events.filter(e=>e.id==max_id)[0].event_date};
	}
}


export function StatusPage() {
    const [pendiente, setPendiente] = useState(_pendiente);
    const [enPreparacion, setEnPreparacion] = useState(_enPreparacion);
    const [enDistribucion, setEnDistribucion] = useState(_enDistribucion);
    const [entregado, setEntregado] = useState(_entregado);
    const [requestId, setRequestId] = useState("");

    const params = useParams();
    const navigate = useNavigate();

	
    const fetchData = async()=>{
        try{
            let res = await axios.get(`${REQUEST_URL}/code/${params.hashPaciente}?addTracking=1`)
            const data = res.data;
            setRequestId(data.id);
		
            if(data.events && data.events.length){
				
                let preparacion_habilitado = habilita_estado_en_preparacion(data.events);
				let despacho_habilitado = habilita_estado_en_despacho(data.events);
				let entregado_habilitado = habilita_estado_entregado(data.events);

				if(habilita_estado_new_order(data.events)){
					setPendiente({...pendiente, date:moment(data.created_at).format("DD/MM/YYYY HH:mm"), display:true})
				}
				if(preparacion_habilitado.habilitado){
					setEnPreparacion({...enPreparacion, date:moment(preparacion_habilitado.fecha).format("DD/MM/YYYY HH:mm"), display:true})
				}
				if(despacho_habilitado.habilitado){
					setEnDistribucion({...enDistribucion, date:moment(despacho_habilitado.fecha).format("DD/MM/YYYY HH:mm"), display:true})
				}
				if(entregado_habilitado.habilitado){
					setEntregado({...entregado, date:moment(entregado_habilitado.fecha).format("DD/MM/YYYY HH:mm"), display:true})
				}
					
                /*
				for(var i in data.events){
                    
                    let actualState = data.events[i].event_type.name;
    
                    if(actualState=='NEW_ORDER'){
                        setPendiente({...pendiente, date:moment(data.created_at).format("DD/MM/YYYY HH:mm"), display:true})
                    }
                    if(habilita_estado_en_preparacion(data.events)){
                        setEnPreparacion({...enPreparacion, date:moment(data.events[i].event_date).format("DD/MM/YYYY HH:mm"), display:true})
                    }
                    if(actualState=='DISPATCH_READY'){
                        setEnDistribucion({...enDistribucion, date:moment(data.events[i].event_date).format("DD/MM/YYYY HH:mm"), display:true})
                    }
                    if(actualState=='ORDER_DELIVERED'){
                        setEntregado({...entregado, date:moment(data.events[i].event_date).format("DD/MM/YYYY HH:mm"), display:true})
                    }
                }
				*/
            }

        }
        catch(err){
            navigate('/pedido/error')
        }


    }
    useEffect((()=>{
        fetchData();
    }), [])

    //const status = "En Distribucion"
    // "En Preparacion"
    // "En Distribucion"
    // "Entregado"

    return (
        <>
            <div className="body-home-patient">
                <div className="row header-int">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div className="col-md-4 col-sm-12  status-logo-container">
                        <p className="text-center">
                            <img className="logo" src="/logo.png"/>
                        </p>
                        <p >Estado de pedido</p>
                        <p >Num. {pad(requestId,5)}</p>
                        {/* <table className="header-status">
                            <tbody>
                                <tr>
                                    <td><p className="text-center"><img className="logo" src="/logo.png"/></p></td>
                                    <td>
                                        {/* <p><i className="fas fa-map-marker-alt"></i></p> */}
                                        {/*<p className="title">Estado de pedido</p>
                                        <p> Num. {pad(requestId,5)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div  className="col-md-4 col-sm-12">

                        <div className="status-timeline">
                            <div className="status-outer">
                                <div className="status-card">
                                    <div className={`status-info ${(pendiente.display)?"":"status-light"}`}>
                                        <p className={`${(pendiente.display)?"status-title-ok":"status-title-ok-light"}`}  data-number="1">{pendiente?.label}</p>
                                        <p className="status-subtitle">{pendiente?.date}</p>
                                        <p className="status-comment">{pendiente?.description}</p>
                                    </div>
                                </div>
                                <div className="status-card">
                                    <div className={`status-info ${(enPreparacion.display)?"":"status-light"}`}>
                                        <p className={`${(enPreparacion.display)?"status-title-ok":"status-title-ok-light"}`}  data-number="2">{enPreparacion?.label}</p>
                                        <p className="status-subtitle">{enPreparacion?.date}</p>
                                        <p className="status-comment">{enPreparacion?.description}</p>
                                    </div>
                                </div>
                                <div className="status-card">
                                    <div className={`status-info ${(enDistribucion.display)?"":"status-light"}`}>
                                        <p className={`${(enDistribucion.display)?"status-title-ok":"status-title-ok-light"}`}  data-number="3">{enDistribucion?.label}</p>
                                        <p className="status-subtitle">{enDistribucion?.date}</p>
                                        <p className="status-comment">{enDistribucion?.description}</p>
                                    </div>
                                </div>
                                <div className="status-card">
                                    <div className={`status-info ${(entregado.display)?"":"status-light"}`}>
                                        <p className={`${(entregado.display)?"status-title-ok":"status-title-ok-light"}`}  data-number="4">{entregado?.label}</p>
                                        <p className="status-subtitle">{entregado?.date}</p>
                                        <p className="status-comment">{entregado?.description}</p>
                                    </div>
                                </div>


                                {/* <div className="status-card">
                                    <div className={`status-info ${(estado.label=="Devuelto")?"status-light":""}`}>
                                        <p className={`${(estado.label=="Devuelto")?"status-title-ok-light":"status-title-ok"}`}  data-number="1">Pendiente</p>
                                        <p className="status-subtitle">{estado?moment(estado.date).format("DD/MM/YYYY HH:mm"):""}</p>
                                        <p className="status-comment">Su solicitud ha sido recibida</p>
                                    </div>
                                </div>

                                <div className="status-card">
                                    <div className={`status-info ${(estado.label!="En Preparacion" && estado.label!="En Distribucion" && estado.label!="Entregado")?"status-light":""}`}>
                                        <h3 className={`${(estado.label!="En Preparacion" && estado.label!="En Distribucion" && estado.label!="Entregado")?"status-title-ok-light":"status-title-ok"}`} data-number="2">En preparacion</h3>
                                        <p className="status-subtitle">{estado?moment(estado.date).format("DD/MM/YYYY HH:mm"):""}</p>
                                        <p className="status-comment">Ya estamos armando su pedido</p>
                                    </div>
                                </div>

                                <div className="status-card">
                                    <div className={`status-info ${(estado.label!="En Distribucion" && estado.label!="Entregado")?"status-light":""}`}>
                                        <h3 className={`${(estado.label!="En Distribucion" && estado.label!="Entregado")?"status-title-ok-light":"status-title-ok"}`} data-number="3">En distribucion</h3>
                                        <p className="status-subtitle">{estado?moment(estado.date).format("DD/MM/YYYY HH:mm"):""}</p>
                                        <p className="status-comment">Su pedido estÃ¡ en viaje</p>
                                    </div>
                                </div>

                                <div className="status-card">
                                    <div className={`status-info ${(estado.label!="Entregado")?"status-light":""}`}>
                                        <h3 className={`${(estado.label!="Entregado")?"status-title-ok-light":"status-title-ok"}`} data-number="4">Entregado</h3>
                                        <p className="status-subtitle">{estado?moment(estado.date).format("DD/MM/YYYY HH:mm"):""}</p>
                                        <p className="status-comment">Ya hemos entregado su pedido</p>
                                    </div>
                                </div> */}

                            {/* { estado.label=="Devuelto" && 
                                <div className="status-card">
                                    <div className="status-info">
                                        <h3 className="status-title-ok" data-number="4">Devuelto</h3>
                                        <p className="status-subtitle">{estado?moment(estado.date).format("DD/MM/YYYY HH:mm"):""}</p>
                                        <p className="status-comment">Su pedido ha sido devuelto</p>
                                    </div>
                                </div>
                            } */}
                            </div>
                        </div>


                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
            </div>
        </>
    )    
}
