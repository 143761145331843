import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Swal from 'sweetalert2';

const LOGIN_URL =  `${process.env.REACT_APP_API_URL}/auth/login`;
//const LOGIN_URL =  `${process.env.REACT_APP_API_URL}`;

export function LoginForm() {

    const { setAuth } = useAuth();

    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e)=>{
        //e.preventDefault();

        if (!validarFormulario()) {
            Swal.fire({
                html: '<p>Revisar datos</p>',
                icon: 'error',
                iconColor:'#F08F00',
                confirmButtonText: '<p>OK</p>',
                confirmButtonColor: '#0A9E95'
            })
            return;
        }
   
        try{
            const res = await axios.post(LOGIN_URL, 
                JSON.stringify({name:user, password}),
                {
                    headers:{'Content-Type':'application/json'},
                    //withCredentials:true
                });

            const token = res?.data?.token;
            const roles = res?.data?.user?.roles;            
            const username = res?.data?.user?.fullname;
            setAuth({user, roles, token, username})

            window.localStorage.setItem('auth', JSON.stringify({user, roles, token, username}))
            
            //document.cookie = `token=${token};max-age=${60*3};path=/;samesite=strict`;
            setUser("");
            setPassword("");
            navigate("/")
        }
        catch(err){
            setError("Error en authenticación")
            window.localStorage.removeItem('auth')
            Swal.fire({
                html: '<p>Usuario o contraseña incorrecto</p>',
                icon: 'error',
                iconColor:'#F08F00',
                confirmButtonText: '<p>OK</p>',
                confirmButtonColor: '#0A9E95'
            })

            //document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }

    }

    const validarFormulario = ()=> {
        if (!user || !password) {
            return false;
        }
        return true;
    }

    useEffect(()=>{
        userRef.current.focus()
    }, [])

    useEffect(()=>{
        setError("")
    }, [user, password])

    return (
        <div className="body-login">
            <div className="row header">
                <div className="col-md-3 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-6 col-sm-12 p-0 logo-container">
                    <img className="logo-cortado" src="/Logo-Ethical.png"/>
                </div>
                <div className="col-md-3 d-none d-md-block">
                    <p></p>
                </div>
            </div>
            
            <div className="row body">
                <div className="col-md-3 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-6 col-sm-12 body-content">
                    <p className="title">Login</p>
                    
                    <div className="form-group">
                        <p className="label"><span><img src="message.png"/></span> <span className="label-text">Usuario</span></p>
                        <p><input className="form-text" type="text"  name ="name" ref={userRef} autoComplete='off' onChange={(e)=>{setUser(e.target.value)}} value={user} required/></p>
                    </div>

                    <div className="form-group">		
                        <p className="label"><span><img src="lock.png"/></span> <span className="label-text">Contraseña</span></p>
                        <p><input className="form-text" type="password"  name ="password"  onChange={(e)=>{setPassword(e.target.value)}} value={password} required/></p>
                    </div>
                    
                    <div className="form-group-actions">
                        <button className="btn-primary" onClick={()=>{handleSubmit()}}>
                            <p>Ingresar</p>
                        </button>
                    </div>
                    <p style={{fontHeight:"8px!important", textAlign:"center", marginTop:"30px", color:"#bcbcbc"}}>v1.0.8 {(process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT=='QA')?' - QA':''}</p>
                </div>
                <div className="col-md-3 d-none d-md-block">
                    <p></p>
                </div>
            </div>

            {/* <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        {mostrarAlerta &&
                        <div className="alert alert-danger" role="alert">
                            Usuario o contraseña inválidos
                        </div>
                        }

                        <Form onSubmit={handleSubmit}>
                            <p className="text-center mb-3"><img src="./lac-light.png"></img></p>
                            <div className="col-12">
                                <Form.Label className="pt-3"> Nombre </Form.Label>
                                <Form.Control type="text" name ="name" ref={userRef} autoComplete='off' onChange={(e)=>{setUser(e.target.value)}} value={user} required/>
                            </div>
                            <div className="col-12">
                                <Form.Label className="pt-3"> Password </Form.Label>
                                <Form.Control type="password" name ="password"  onChange={(e)=>{setPassword(e.target.value)}} value={password} required/>
                            </div>
                            <div className="col-12 mt-5 text-center">
                                <Button type="submit" variant="primary">
                                    Ingresar
                                </Button>
                            </div>                            
                        </Form>
                    </div>
                </div>
            </div> */}
        </div>
    )
}