import axios from 'axios';
import React, { useContext } from 'react';
import { Link, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { HomeDoctor } from './components/HomeDoctor';
import { Layout } from './components/Layout';
import { LoginForm } from './components/LoginForm';
import { PatientRequest } from './components/PatientRequest';
import { StatusPage } from './components/StatusPage';
import { ProductList } from './components/ProductList';
import { ProductRequest } from './components/ProductRequest';
import RequireAuth from './components/RequireAuth';
import { ThankYouPage } from './components/ThankYouPage';
import { ThankYouPagePatient } from './components/ThankYouPagePatient';
import { ErrorPage } from './components/ErrorPage';

const ROLES = {
  'BACKOFFICE': 1,
  'DOCTOR': 2,
  'ADMIN': 3
}
const baseURL = `${process.env.REACT_APP_API_URL}`;

function App() {
  
  // (function() {
  //     const token = localStorage.getItem("auth")?JSON.parse(localStorage.getItem("auth")).token:null;

  //     axios.defaults.baseURL = baseURL;

  //     if (token) {
  //         axios.defaults.headers.common['Authorization'] = token.toString();
  //     } else {
  //         axios.defaults.headers.common['Authorization'] = null;
  //         /*if setting null does not remove `Authorization` header then try     
  //           delete axios.defaults.headers.common['Authorization'];
  //         */
  //     }  
  // })();


  return (
    // <div className='container'>
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route path="login" element={<LoginForm />} />

          <Route element={<RequireAuth allowedRoles={[ROLES.DOCTOR]} />}>
            <Route index element={<HomeDoctor />} />
            <Route path="productos" element={<ProductList />} />
            <Route path="pedido-producto" element={<ProductRequest />} />
            <Route path="enviado" element={<ThankYouPage />} />
          </Route>

          <Route path="pedido/:hashPaciente" element={<PatientRequest />} />
          <Route path="pedido/status/:hashPaciente" element={<StatusPage />} />
          <Route path="pedido/enviado/:hashPaciente" element={<ThankYouPagePatient />} />
          <Route path="pedido/error" element={<ErrorPage />} />

          <Route path="*" element={<Navigate to={"/"} />} />

        </Route>
      </Routes>
    // </div>
  );
}

export default App;
