import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "./Product";
import  Select  from 'react-select';
import axios from 'axios';

const PRODUCT_URL =  `${process.env.REACT_APP_API_URL}/product`;


// const brandList =  [
//     {value:1, label:"Sancor Bebé Advanced"}, 
//     {value:2, label:"Enfabebé"}, 
//     {value:3, label:"Nutribaby"}, 
//     {value:4, label:"Nutramigen"},
//     {value:5, label:"BioGaia"}
// ] 

const sorter = (a, b) => {
    let stockA = a.qty_max - a.consumed;
    let stockB = b.qty_max - b.consumed;

    if(stockA<=0)
        return 1;
    else if(stockB<=0)
        return -1
    else 
        return 0;
 };

export function ProductList() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])
    const [productList, setProductList] = useState([])
    const [brandList, setBrandList] = useState([])
    const [category, setCategory] = useState(null)

    const token = localStorage.getItem("auth")?JSON.parse(localStorage.getItem("auth")).token:null;
    const configGetProductos = {
        method:'get',
        url:PRODUCT_URL,
        //withCredentials:true,
        headers:{ 
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }
    const handleChangeCategory=(e)=>{
        setCategory(e);
        //setProducts(productList.filter(p=>p.name.toUpperCase().includes(e.label)))
        setProducts(productList.filter(p=>p.brand.id==e.value).sort(sorter))
    }

    const stylesSelectCategory = { 
        menuPortal: (base) => ({ ...base, zIndex: 9999}),
        control: (css) => ( {...css,  width: "100%", marginLeft:"2px", height:"50px", borderRadius:"10px", fontFamily: 'Raleway'  }),
        menu: ({ width, ...css }) => ({
            ...css,
            width: "max-content"       
            }),
        option: (css) => ({ ...css, width: "100%", fontFamily: 'Raleway' }),        
        container: base => ({
            ...base,
            width:"100%",
            flex: 1
          })
    }
    const realizarPedido=(producto)=>{
        if(producto.qty_max <= producto.consumed)
            return;

        navigate('/pedido-producto', { state: { producto } })
    }

    useEffect(()=>{
        const cargarDatos = async()=>{
            const resp =await axios(configGetProductos)

            if(resp && resp.data && resp.data.results){
                
                const products = resp.data.results;

                const uniqueBrands = products.reduce((acc, current) => {
                    const existingBrand = acc.find(item => item.id === current.brand.id);
                    if (!existingBrand) {
                        acc.push(current.brand);
                    }
                    return acc;
                }, []);

                //Tengo por default Sancor bebe advanced
                // setCategory({value:1, label:"Sancor Bebé Advanced"});

                setBrandList(uniqueBrands.map(b=>({label:b.name, value:b.id})))
                
                setProductList(products);
            }


            setCategory(brandList[0] || null);
        }
        cargarDatos();
    }, [])

    useEffect(()=>{
        if(productList && productList.length && category){
            setProducts(productList.filter(p=>p.brand.id==category.value).sort(sorter))
        }
    }, [productList])



    return (
        <>
            
            <div className="body-products">
    
                <div className="row header-int">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <p className="goback" onClick={()=>navigate('/')}></p> 
                        <p className="title text-center">Muestra médica</p>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
                {/* <ul> */}
                    {/* {products && products.map((p)=>(
                        <Product key={p.id} producto={p} realizarPedido={realizarPedido}/>
                    ))} */}
                    	<div className="row">
                            <div className="col-md-4 d-none d-md-block">
                                <p></p>
                            </div>
                            <div className="col-md-4 col-sm-12 body-content-int">
                                
                                <p className="subtitle">Categoria</p>
                                <Select
                                    className="form-select"
                                    name="form-field-name-3"
                                    styles={stylesSelectCategory}
                                    options={brandList} 
                                    onChange={handleChangeCategory} 
                                    value={category?category:null}
                                    menuPortalTarget={document.body}
                                    autoComplete="nope"
                                    //menuPlacement="top"
                                    isDisabled={false} required
                                />

                                {/* <select className="form-control form-select">
                                    <option selected>NUTRIBABY</option>
                                    <option>ENFABEBE</option>
                                    <option>SANCOR BEBE</option>
                                </select> */}
<ul>
			                    {products && products.map((p)=>(
                                    <Product key={p.id} producto={p} realizarPedido={realizarPedido}/>
                                ))}
</ul>
                            </div>
                            <div className="col-md-4 d-none d-md-block">
                                <p></p>
                            </div>
	                    </div>
                {/* </ul> */}
            </div>
        </>
    )    
}
