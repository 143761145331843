
export function ErrorPage() {

    return (
        <>
            <div className="body-home-patient">
                <div className="row header-int">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                <div className="col-md-4 col-sm-12 logo-container">
                    <p className="text-center">
                        <img className="logo" src="/logo.png"/>
                    </p>
                </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div  className="col-md-4 col-sm-12 error-card">
                        <p>Pedido no encontrado</p>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
            </div>
        </>
    )    
}
