import { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

const REQUEST_PDF = `${process.env.REACT_APP_PDF_URL}`;

export function HomeDoctor() {
    const { setAuth, auth } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        setAuth({});
        window.localStorage.removeItem('auth')
        navigate('/login');
    }

    const productos=()=>{
        navigate('/productos')
    }

    const abbr = (str)=>{
        return str.split(' ').map(function(item){return item[0]}).join('');
    }

    return (
        <div className="body-home">
            <div className="row header-int">
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-4 col-sm-12 logo-container">
                    <p className="text-center">
                        <img className="logo" src="/logo.png"/>
                    </p>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-4 col-sm-12 body-content-int">
                    <div className="ecard-with-avatar">
                        <div className="avatar">
                            <p>{auth?abbr(auth.username):""}</p>
                        </div>
                        <p className="title">{auth?.username}</p>
                    </div>
                    
                    <div className="ecard pointer"  onClick={productos}>
                        <p className="title"><span><i className="fas fa-medkit"></i></span> <span>Muestra médica</span> <span></span></p>
                    </div>
                    
                    <div className="ecard pointer" >
                        <p className="title"><a href={REQUEST_PDF}  target="_blank"><span><i className="fas fa-shopping-cart"></i></span> <span>Información de producto</span> <span></span></a></p>
                    </div>
                    <div className="ecard-salir pointer" onClick={logout}>
                        <i className="fas fa-sign-out-alt"></i><span>Salir</span>
                        
                        {/* <button  className="btn-primary"  onClick={logout} >Salir</button> */}

                    </div>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
            </div>

        </div>
    )    
}


            {/* <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Form >
                            <h3 className="text-center">Home</h3>
                            <div className="col-12 mt-5 text-center">
                                <Button type="submit" variant="primary" onClick={productos}>
                                    Productos
                                </Button>
                            </div>
                            <div className="col-12 text-center mt-2">
                                <Button type="submit" variant="secondary" onClick={logout}>
                                    Salir
                                </Button>
                            </div>
                            
                        </Form>
                    </div>
                </div>
            </div> */}