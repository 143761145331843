
import { Link, useNavigate, useParams } from "react-router-dom";

export function ThankYouPagePatient() {
    const params = useParams();

    
    return (
        <>
            <div className="body-home-patient">
                <div className="row header-int">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <table className="header-patient-cont">
                            <tbody>
                                <tr>
                                    <td><p className="text-center"><img className="logo" src="/logo.png"/></p></td>
                                    <td><p className="title"></p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div  className="col-md-4 col-sm-12 error-card">
                        <p>Su pedido ha sido enviado con éxito</p>
                        <p>Hemos enviado un email a la dirección indicada con los detalles de la entrega</p>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col status-link mt-3">
                        <Link to={`/pedido/${params.hashPaciente}`}>Ver estado del envío</Link>
                    </div>
                </div>
            </div>

        </>
    )    
}
