import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export function ThankYouPage() {
    const navigate = useNavigate();

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Form >
                            <div className="col-12 text-center">
                                <h4>Su pedido ha sido enviado con éxito</h4>
                            </div>
                            <div className="col-12 text-center mt-5">
                                <Button type="submit" variant="secondary" onClick={()=>navigate('/productos')}>
                                    Volver
                                </Button>
                            </div>
                            
                        </Form>
                    </div>
                </div>
            </div>

        </>
    )    
}
