import { Button } from "react-bootstrap";



export function Product({producto, realizarPedido}) {
    let disponible="";
    if(producto){
        if(producto.qty_max <= producto.consumed){
            disponible="Sin stock"
        }
        else{
            disponible = (producto.qty_max - producto.consumed).toString();
        }
    }
    return (

            <li>
                <div className="ecard">
                    <table className="product-cont">
                        <tbody>
                            <tr>
                                <td rowSpan="2">
                                    <p className="text-center"><img className="product-img" src={producto?producto.image_full_path:""}/></p>
                                </td>
                                <td>
                                    <p className="product-title">{producto?.name}</p>
                                    <p className="product-title-presentation">{producto?.presentation}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="product-stock">
                                    <p>Stock</p>
                                    <p><span className="text-bold">Inicial: </span><span>{producto?producto.qty_max:""}</span></p>
                                    <p><span className="text-bold">Disponible: </span><span className={disponible=='Sin stock'?"text-danger text-bold":""}>{disponible}</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <button 
                        className={`btn-primary btn-solicitar ${(producto.qty_max<=producto.consumed)? ' button-disabled':''}`}  
                        onClick={()=>realizarPedido(producto)}  disabled={producto.qty_max<=producto.consumed}>
                            {(producto.qty_max<=producto.consumed)?
                                <p>En caso de necesitar más inicios de tratamiento por favor comunicarse con su APM</p>
                            :
                                <p>Solicitar</p>
                            }
                    </button>
                </div>
            </li>


        // <li>
        //     <div className="item" >
        //         <img alt="Pic" src={producto?producto.image_full_path:""} width="250px"/>
        //         <h4>{producto?.name}</h4>
        //         <table>
        //             <tbody>
        //                 <tr>
        //                     <td><p>Stock Inicial</p></td>
        //                     <td><p>{producto?producto.qty_max:""}</p></td>
        //                 </tr>
        //                 <tr>
        //                     <td><p>Stock Disponible</p></td>
        //                     <td><p>{producto?producto.qty_max - producto.consumed:""}</p></td>
        //                 </tr>
        //                 <tr>
        //                     <td colSpan={2}><Button  onClick={()=>realizarPedido(producto)} disabled={producto.qty_max<=producto.consumed}>Solicitar</Button ></td>
        //                 </tr>
        //             </tbody>
        //         </table>
        //     </div>

        // </li>
    )    
}
