import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const PRODUCT_REQUEST_URL =  `${process.env.REACT_APP_API_URL}/product/request`;

const pacienteInicial={
    nombre:"",
    apellido:"",
    email:"",
    documento:""
}
const validarEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function ProductRequest() {

    const [paciente, setPaciente] = useState(pacienteInicial);
    const [cargando, setCargando] = useState(false);
    
    const emailRef = useRef();
    const btnRef = useRef();
    
    const navigate = useNavigate();
    const {state} = useLocation();
    const {producto} = state;
    
    const token = localStorage.getItem("auth")?JSON.parse(localStorage.getItem("auth")).token:null;

    const configProductRequest = {
        method:'post',
        url:PRODUCT_REQUEST_URL,
        //withCredentials:true,
        headers:{ 
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }

    const validarFormulario = ()=> {
        if ( !paciente.email) {
            return false;
        }
        if (!validarEmail(paciente.email)) {
            return false;
        }
        return true;
    }

    const handleChange=(event)=>{
        setPaciente({...paciente, [event.target.name]:event.target.value})
    }

    const realizarPedido = async ()=>{
        
        btnRef.current.setAttribute("disabled", "disabled");

        if(cargando)
            return;

        if (!validarFormulario()) {
            Swal.fire({
                html: '<p>Email incorrecto</p>',
                icon: 'error',
                iconColor:'#F08F00',
                confirmButtonText: '<p>OK</p>',
                confirmButtonColor: '#0A9E95'
            })
            btnRef.current.removeAttribute("disabled");
            return;
        }

        setCargando(true);
        const pedido={
            email: paciente.email,
            lines: [
                {
                    product_id: producto.id,
                    qty: 1
                }
            ]
        }
        
        try{
            
            await axios({...configProductRequest, data:pedido})
            
            Swal.fire({
                html: '<p>Se ha enviado el link al mail indicado</p><p>El mismo será válido por 72 hs.</p>',
                icon: 'success',
                iconColor:"#0BCF1F",
                confirmButtonText: '<p>Volver</p>',
                confirmButtonColor: '#0A9E95'
            }).then((result) => {
                navigate('/productos');
                
            })    
        }
        catch(err){
            console.log(err)
            setCargando(false);
            let msg = '<p>Ocurrió un error al enviar el link</p>';
            if(err && err.response && err.response.data){
                msg = `<p>${err.response.data.message}</p>`
            }
            

            Swal.fire({
                html: msg,
                icon: 'error',
                iconColor:'#F08F00',
                confirmButtonText: '<p>Volver</p>',
                confirmButtonColor: '#0A9E95'
            }).then((result) => {
                navigate('/productos');
            })  
        }        
    }

    useEffect(()=>{
        emailRef.current.focus()
    }, [])


    return (
        <>
            <div className="product-request">
                
                <div className="row header-int">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <p className="goback" onClick={()=>navigate(-1)}></p> 
                        <p className="title text-center">{producto.name || ''}</p>
                        <p className="subtitle text-center">{producto.presentation || ''}</p>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                        <div className="img-frame text-center">
                            <img src={producto?producto.image_full_path:""}/>
                        </div>
                    </div>                  


                    <div className="col-md-4 d-none d-md-block">
                        <p></p>
                    </div>                        
                </div>

                <div className="row">
                    <div className="col-md-4 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm12 body-content">

                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Email *</span></p>
                                <p className="mt-18"><input  ref={emailRef} className="form-text bordered" type="text" name ="email"  onChange={handleChange} value={paciente.email || ""} maxLength={50}/></p>
                            </div>
                            <div>
                                <Button ref={btnRef} type="button" variant="primary" onClick={realizarPedido}>
                                    {!cargando?"Enviar Pedido":"Enviando..."}
                                </Button>
                            </div>
                            {/* <Button type="button" variant="primary" onClick={()=>{navigate('/pedido/status')}}>STATUS
                            </Button> */}
                        </div>
                    <div className="col-md-4 d-none d-md-block"></div>
                </div>
            </div>
        </>
    )
}
