import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { matchRoutes, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import  Select  from 'react-select';
import Swal from 'sweetalert2';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const TERMS_PAGE = `${process.env.REACT_APP_TERMS_URL}`;
const REQUEST_URL = `${process.env.REACT_APP_API_URL}/product/request`;
const RIGA_URL = `https://riga.shopdrofar.com.ar`;

const pacienteInicial={
    patient_first_name: "",
    patient_last_name: "",
    patient_tax_number: "",
    resp_first_name: "",
    resp_last_name: "",
    resp_tax_number: "",
    resp_email: "",
    resp_phone: "",
    resp_subscribe:1,
    state_id: null,
    locality_id: null,
    street:"",
    postal_code: "",
    street_number: "",
    floor: "",
    apartment: "" 
}
const initialAddress = {
    state_id: null,
    locality_id: null
}

const transformPatientData = (paciente, data)=>{
    return {
        ...paciente,
        email:data.email,
        patient_first_name: data.patient.first_name,
        patient_last_name:  data.patient.last_name,
        patient_tax_number: data.patient.tax_number,
        resp_first_name:  data.responsible.first_name,
        resp_last_name:  data.responsible.last_name,
        resp_tax_number:  data.responsible.tax_number,
        resp_email:  data.responsible.email,
        resp_phone:  data.responsible.phone,
        resp_subscribe:data.responsible.subscribe
    }
}


export function PatientRequest() {

    const [address, setAddress] = useState(initialAddress);
    const [geoStates, setGeoStates] = useState([]);
    const [geoLocalities, setGeoLocalities] = useState([]);
    const [validated, setValidated] = useState(false);
    const [paciente, setPaciente] = useState(pacienteInicial);
    const [onStep1, setOnStep1] = useState(true);
    const [subscribe, setSubscribe] = useState(true);
    const [terminos, setTerminos] = useState(true);
    const [birthdate, setBirthdate] = useState("");
    const [errorDate, setErrorDate] = useState(false);
    const [doctor, setDoctor] = useState(null);
    const [product, setProduct] = useState({});

    const btnRef = useRef();
    const params = useParams();

    const [hashValido, setHashValido] = useState(false);
    const [cargando, setCargando] = useState(true);

    const navigate = useNavigate();

    const selectGeoState = async (e)=>{
        setAddress({...address, state_id:e.value, locality_id:null});
        const response =await axios.get(`${RIGA_URL}/api/geo/locality?filter={"state_id":${e.value}}`);
        const localities = response.data.results.map(item=>({label:item.name, value:item.id}))
        setGeoLocalities(localities);
    }

    const selectGeoLocality = (e)=>{
        setAddress({...address, locality_id:e.value});
    }

    const handleChange= (event)=>{
        let value = event.target.value;

        if(event.target.name=='patient_tax_number' || event.target.name=='resp_tax_number' || event.target.name=='postal_code'  || event.target.name=='street_number' || event.target.name=='resp_phone'){
            value = event.target.value.replace(/\D/g, '');
        }        
        
        setPaciente({...paciente, [event.target.name]:value})

    }

    const handleDateChange = (e)=>{
        var test =e.target.value.split("/").join("");
        setErrorDate(false);

        if(!isNaN(e.target.value.split("/").join(""))){
            // setBirthdate(e.target.value);
        }
        else{
            
        }
        setBirthdate(e.target.value);
    }


    const realizarPedido = async ()=>{
        
        btnRef.current.setAttribute("disabled", true);

        try{
            if(isNaN(birthdate.split("/").join("")) || !moment(birthdate, "DD/MM/YYYY").isValid()){
                setErrorDate(true);
                Swal.fire({
                    html: '<p>Fecha inválida</p>',
                    icon: 'error',
                    iconColor:'#F08F00',
                    confirmButtonText: '<p>OK</p>',
                    confirmButtonColor: '#0A9E95'
                })
                btnRef.current.removeAttribute("disabled");
                return;
            }

        }
        catch(err){
            setErrorDate(true);
            Swal.fire({
                html: '<p>Fecha inválida</p>',
                icon: 'error',
                iconColor:'#F08F00',
                confirmButtonText: '<p>OK</p>',
                confirmButtonColor: '#0A9E95'
            })
            btnRef.current.removeAttribute("disabled");
            setCargando(false);
            return;
        }

        if(!paciente.patient_first_name || !paciente.resp_first_name || !paciente.resp_last_name ||
            !paciente.resp_tax_number || !paciente.resp_phone || !paciente.email || !address.state_id || !address.locality_id || !paciente.street || !paciente.street_number || !paciente.postal_code ||
            paciente.street.trim()=='' || paciente.street_number.trim()=='' || paciente.postal_code.trim()==''){
                Swal.fire({
                    html: '<p>Faltan completar datos</p>',
                    icon: 'error',
                    iconColor:'#F08F00',
                    confirmButtonText: '<p>OK</p>',
                    confirmButtonColor: '#0A9E95'
                })
                btnRef.current.removeAttribute("disabled");
                setCargando(false);
                return;
        }
        

        if(paciente.resp_phone.length!=10){
                Swal.fire({
                    html: '<p>El teléfono no tiene el formato correcto</p>',
                    icon: 'error',
                    iconColor:'#F08F00',
                    confirmButtonText: '<p>OK</p>',
                    confirmButtonColor: '#0A9E95'
                })
                btnRef.current.removeAttribute("disabled");
                setCargando(false);
                return;
        }

        const addr = { 
            state_id:address.state_id, 
            locality_id:address.locality_id,
            street:paciente.street, 
            street_number:paciente.street_number, 
            postal_code:paciente.postal_code,
            floor:paciente.floor,
            apartment:paciente.apartment
        }

        const d = moment(birthdate, "DD/MM/YYYY")

        const pedido={
            patient_first_name: paciente.patient_first_name,
            patient_last_name: paciente.patient_last_name,
            patient_tax_number: paciente.patient_tax_number,
            patient_birth_date: d.toDate(),
            resp_first_name: paciente.resp_first_name,
            resp_last_name: paciente.resp_last_name,
            resp_tax_number: paciente.resp_tax_number,
            resp_phone: paciente.resp_phone,
            resp_email: paciente.email,
            resp_subscribe: subscribe,
            address: addr
        }

        setCargando(true);
        const res = await axios.put(`${REQUEST_URL}/code/${params.hashPaciente}`, pedido)
        setCargando(false);

        navigate(`/pedido/enviado/${params.hashPaciente}`);
    }




    useEffect(()=>{
        const cargarDatos = async()=>{

            try{

                let response = await axios.get(`${RIGA_URL}/api/geo/state`);

                const states = response.data.results.map(item=>({label:item.name, value:item.id}))

                setGeoStates(states);

                let res = await axios.get(`${REQUEST_URL}/code/${params.hashPaciente}?addTracking=1`)

                if (res.data.status.id != 1) {
                    navigate(`/pedido/status/${params.hashPaciente}`);
                }

                setPaciente(transformPatientData(paciente, res.data));
                setDoctor(res.data.doctor);

                if(res.data.lines){
                    setProduct(res.data.lines[0])
                }
                
                setHashValido(true);
                setCargando(false);

            }
            catch(err){
                //aca salta por status http != 200
                setHashValido(false);
                setCargando(false);
                navigate('/pedido/error')
            }

        }
        cargarDatos();

    }, [])



    const stylesSelectState = { 
        menuPortal: (base) => ({ ...base, zIndex: 9999}),
        control: (css) => ( (validated && !address.state_id)? 
            ({ ...css, width: "100%", marginLeft:"2px", border:"solid 1px red", height:"50px", borderRadius:"10px", fontFamily: 'Raleway' })
            :
            ({...css,  width: "100%", marginLeft:"2px", height:"50px", borderRadius:"10px", fontFamily: 'Raleway'})),
        menu: ({ width, ...css }) => ({
            ...css,
            width: "max-content"       
            }),
        option: (css) => ({ ...css, width: "100%", fontFamily: 'Raleway' }),
        container: base => ({
            ...base,
            flex: 1
          })
    }
    const stylesSelectLocality = { 
        menuPortal: (base) => ({ ...base, zIndex: 9999}),
        control: (css) => ( (validated && !address.locality_id)? 
            ({ ...css, width: "100%", marginLeft:"2px", border:"solid 1px red", height:"50px", borderRadius:"10px", fontFamily: 'Raleway'  })
            :
            ({...css,  width: "100%", marginLeft:"2px", height:"50px", borderRadius:"10px", fontFamily: 'Raleway'  })),
        menu: ({ width, ...css }) => ({
            ...css,
            width: "max-content"       
            }),
        option: (css) => ({ ...css, width: "100%", fontFamily: 'Raleway' }),
        container: base => ({
            ...base,
            flex: 1
          })
    }
    // <i className="fas fa-check"></i>

    if(!doctor){
        return (
            <div className="status-spinner">  
                <Spinner animation="border" role="status">
                </Spinner>
            </div>
        );
    }
        

    return (
        <div className="body-home-patient">

            <div className="row header-int">
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-4 col-sm-12 patient-logo-container">
                    <p className="text-center">
                        <img className="logo" src="/logo.png"/>
                    </p>
                    <p className="title">Formulario de envío</p>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
            </div>
            <div className="row m-3">
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-4 col-sm-12 d-md-block text-center">
                    <p>La leche materna es el mejor alimento para los bebés</p>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
                <div className="col-md-4 col-sm-12 body-content-int">
                    <div id="steps">
                        <div className={onStep1?"step done pointer":"step pointer"} data-desc="Datos personales"  onClick={()=>setOnStep1(true)}>{onStep1?<i className="fas fa-check"></i>:"1"}</div>
                        <div className={!onStep1?"step done pointer":"step pointer"} data-desc="Dirección de envío" onClick={()=>setOnStep1(false)}>{!onStep1?<i className="fas fa-check"></i>:"2"}</div>
                    </div>
                    
                    { onStep1 &&    
                        <div className="form-cont">  
                            <div className="row">
                                <p className="title"><i className="fas fa-shopping-basket"></i> Su tratamiento</p>
                            </div>
                            <div className="row form-group-lite">
                                <table className="product-cont">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="text-center"><img className="product-img" src={product?.image_full_path}/></p>
                                            </td>
                                            <td>
                                                <p className="product-title">{product?.name}</p>
                                                <p className="product-title-presentation">{product?.presentation}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="form-separator"></div>
                            <div className="row">
                                <p className="title"><i className="fas fa-briefcase-medical"></i> Datos del médico</p>
                            </div>
                            <div className="row form-group-lite">
                                <p><input className="form-text bordered" readOnly type="text" value={'Dr. ' + doctor?.first_name + ' ' + doctor?.last_name}/></p>
                            </div>
                            
                            <div className="form-separator"></div>
                            <div className="row">
                                <p className="title"><i className="fas fa-user"></i> Datos del solicitante</p>
                            </div>
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Email</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text" name ="email"  onChange={handleChange} value={paciente.email || ""} maxLength={50} readOnly/></p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Nombre del solicitante</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text" name ="resp_first_name" autoComplete='off' onChange={handleChange} value={paciente.resp_first_name || ""} maxLength={100}/></p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Apellido del solicitante</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text"  name ="resp_last_name" autoComplete='off'   onChange={handleChange} value={paciente.resp_last_name || ""} maxLength={100}/></p>
                            </div>

                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Documento del solicitante</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text"  name ="resp_tax_number" autoComplete='off'   onChange={handleChange} value={paciente.resp_tax_number || ""} maxLength={10}/></p>
                            </div>

                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Teléfono móvil (ej. 1144556677)</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text" name ="resp_phone" autoComplete='off'
                                    onChange={handleChange} value={paciente.resp_phone || ""} maxLength={10}/></p>
                            </div>

                            <div className="form-separator"></div>
                            <div className="row">
                                <p className="title"><i className="fas fa-baby-carriage"></i> Datos del paciente</p>
                            </div>
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Nombre del paciente</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text" name ="patient_first_name" autoComplete='off' onChange={handleChange} value={paciente.patient_first_name || ""} maxLength={100}/></p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Fecha de nacimiento del paciente</span></p>
                                <div className="mt-18 datepicker-container">
                                    <InputMask  mask='99/99/9999'  className={'form-text bordered'} value={birthdate} onChange={handleDateChange} />
                                </div>
                            </div>

                            <button className="btn-primary" onClick={()=>setOnStep1(false)}>
                                <p>Siguiente</p>
                            </button>

                        </div>
                    }
                    
                    { !onStep1 &&                    
                        <div className="form-cont">
                            <div className="row">
                                <p className="title"><i className="fas fa-map-marker"></i> Dirección</p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label title-address"><span className="label-text">Provincia</span></p>
                                <Select
                                    name="form-field-name-2"
                                    styles={stylesSelectState}
                                    options={ geoStates } 
                                    onChange={(e)=>selectGeoState(e)} 
                                    value={address.state_id?geoStates.filter(e=>e.value==address.state_id)[0]:null}
                                    menuPortalTarget={document.body}
                                    autoComplete="nope" 
                                />
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label title-address"><span className="label-text">Localidad</span></p>
                                <Select
                                    className="form-select"
                                    name="form-field-name-3"
                                    styles={stylesSelectLocality}
                                    options={ geoLocalities } 
                                    onChange={(e)=>selectGeoLocality(e)} 
                                    value={address.locality_id?geoLocalities.filter(e=>e.value==address.locality_id)[0]:null}
                                    menuPortalTarget={document.body}
                                    autoComplete="nope"
                                    //menuPlacement="top"
                                    isDisabled={false} required
                                />
                            </div>

                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">CP</span></p>
                                <p className="mt-20"><input className="form-text bordered" type="text" name ="postal_code" autoComplete='off' onChange={handleChange} value={paciente.postal_code} required maxLength={4}/></p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <p className="label"><span className="label-text">Calle</span></p>
                                <p className="mt-18"><input className="form-text bordered" type="text" name ="street" autoComplete='off' onChange={handleChange} value={paciente.street} required maxLength={100}/></p>
                            </div>
                            
                            <div className="row form-group-lite">
                                <table className="form-group-lite-x2">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className="label"><span className="label-text">Altura</span></p>
                                            <p className="mt-20"><input className="form-text bordered" type="text" autoComplete='off' name ="street_number"  onChange={handleChange} value={paciente.street_number} required maxLength={6}/></p>
                                        </td>
                                        <td>
                                            <p className="label"><span className="label-text">Piso / Depto</span></p>
                                            <p className="mt-20"><input className="form-text bordered" type="text" autoComplete='off' name ="floor"  onChange={handleChange} value={paciente.floor} required maxLength={20}/></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row form-group-lite">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name="habilitado_farmacia"
                                        checked={subscribe}
                                        onChange={()=>setSubscribe(!subscribe)}
                                    />
                                    <span className="label-subscription">Quiero recibir información sobre productos y promociones</span>
                                </label>
                            </div>
                            <div className="row form-group-lite">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        name="acepto_terminos"
                                        checked={terminos}
                                        onChange={()=>setTerminos(!terminos)}
                                    />
                                    <span className="label-subscription terminos-link">Acepto <a href={TERMS_PAGE}  target="_blank">Términos y Condiciones</a></span>
                                </label>
                            </div>

                            <button className="btn-primary" ref={btnRef}  onClick={realizarPedido} disabled={!terminos}>
                                    {!cargando?"Enviar Formulario":"Enviando..."}
                            </button>
                            
                        </div>
                    }
                    
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <p></p>
                </div>
            </div>
        </div>
    )
}

{/* <div className="container">
  
{!codigoValido && !cargando &&
    <div className="alert alert-danger mt-5" role="alert">
        Pedido no encontrado
    </div>
}

{codigoValido && !cargando &&
    <Form onSubmit={handleSubmit}>
        <div  className="col-12">
            <h3 className="text-center">{producto.name || ''}</h3>
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Nombre Paciente</Form.Label>
            <Form.Control type="text" name ="patient_first_name" autoComplete='off' onChange={handleChange} value={paciente.patient_first_name || ""} maxLength={100}/>
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Apellido Paciente</Form.Label>
            <Form.Control type="text" name ="patient_last_name"  onChange={handleChange} value={paciente.patient_last_name || ""} maxLength={100} />
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Documento Paciente</Form.Label>
            <Form.Control type="text" name ="patient_tax_number"  onChange={handleChange} value={paciente.patient_tax_number || ""} maxLength={30} />
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Nombre Responsable</Form.Label>
            <Form.Control type="text" name ="resp_first_name" autoComplete='off' onChange={handleChange} value={paciente.resp_first_name || ""} maxLength={100}/>
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Apellido Responsable</Form.Label>
            <Form.Control type="text" name ="resp_last_name"  onChange={handleChange} value={paciente.resp_last_name || ""} maxLength={100} />
        </div>
        <div className="col-12">
            <Form.Label className="pt-3"> Documento Responsable</Form.Label>
            <Form.Control type="text" name ="resp_tax_number"  onChange={handleChange} value={paciente.resp_tax_number || ""} maxLength={30} />
        </div>        
        <div className="col-12">
            <Form.Label className="pt-3"> Email Responsable</Form.Label>
            <Form.Control type="text" name ="email"  onChange={handleChange} value={paciente.email || ""} maxLength={30} readOnly/>
        </div>          
        <div className="col-12">
            <Form.Label className="pt-3"> Teléfono Responsable</Form.Label>
            <Form.Control type="text" name ="resp_phone"  onChange={handleChange} value={paciente.resp_phone || ""} maxLength={30} />
        </div>                      
        <div className="row mt-3">
            <div className="col-12 col-md-4">
                <Form.Group>
                    <Form.Label>Provincia</Form.Label>                            
                    <Select
                        name="form-field-name-2"
                        styles={stylesSelectState}
                        options={ geoStates } 
                        onChange={(e)=>selectGeoState(e)} 
                        value={address.state_id?geoStates.filter(e=>e.id==address.state_id)[0]:null}
                        menuPortalTarget={document.body}
                        autoComplete="nope" 
                    />
                </Form.Group>
            </div>
            <div className="col-12 col-md-4">
                <Form.Group>
                    <Form.Label>Localidad</Form.Label>
                    <Select
                        name="form-field-name-3"
                        styles={stylesSelectLocality}
                        options={ geoLocalities } 
                        onChange={(e)=>selectGeoLocality(e)} 
                        value={address.locality_id?geoLocalities.filter(e=>e.id==address.locality_id)[0]:null}
                        menuPortalTarget={document.body}
                        autoComplete="nope"
                        //menuPlacement="top"
                        isDisabled={false} required
                    />
                </Form.Group>
            </div>
            <div className="col-12 col-md-4">
                <Form.Group>
                    <Form.Label> CP </Form.Label>
                    <Form.Control type="text" name ="postal_code"  onChange={handleChange} value={paciente.postal_code} required maxLength={10}/>
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-md-6">
                <Form.Label className="pt-3"> Calle </Form.Label>
                <Form.Control type="text" name ="street"  onChange={handleChange} value={paciente.street} required maxLength={100}/>
            </div>
            <div className="col-12 col-md-3">
                <Form.Label className="pt-3"> Altura </Form.Label>
                <Form.Control type="text" name ="street_number"  onChange={handleChange} value={paciente.street_number} required maxLength={6}/>
            </div>
            <div className="col-12 col-md-3">
                <Form.Label className="pt-3"> Piso/Dpto </Form.Label>
                <Form.Control type="text" name ="floor"  onChange={handleChange} value={paciente.floor} required maxLength={20}/>
            </div>
        </div>

        <div className="col-12 mt-5 text-center">
            <Button type="button" variant="primary" onClick={realizarPedido}>
                Enviar Pedido
            </Button>
        </div>
    </Form>
}

</div> */}